.dashboard_page {
    width: 100vw;
    height: 92vh;
    box-sizing: border-box;
}

#group-button-container {
    width: 100vw;
    height: 92vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

#group-button-left,
#group-button-right {
    width: 48vw;
    height: 70vh;
    margin: 0 1vh;
}

#group-button-container a {
    box-sizing: border-box;
    color: white;
    width: 48vw;
    height: 34vh; 
    font-size: 2em;
    border: 2px solid black;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#group-button-container a.new-group {
    background-color: limegreen;
    height: 100%;
}

#group-button-container a.view-groups {
    background-color: steelblue;
}

#group-button-container a.find-group {
    background-color: orange;
}
