#groups-section {
    width: 100vw;
    height: 90vh;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center
}

#groups-section a {
    box-sizing: border-box;
    width: 40%;
    height: 20vh;
    margin: 0 5%;
    border: 2px solid black;
    margin: 12px 0;
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

#groups-section a:nth-of-type(4n + 1) {
    background-color: yellow;
}

#groups-section a:nth-of-type(4n + 2) {
    background-color: pink;
}

#groups-section a:nth-of-type(4n + 3) {
    background-color: orange;
}

#groups-section a:nth-of-type(4n + 0) {
    background-color: lightgreen;
}

#groups-section a h3,p {
    margin: 0;
}
