div.postit {
    background-color: yellow;
    border: 2px solid;
    width: 90%;
    margin: 10vh 5vw;
    margin-bottom: 2vh;
    height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

div.postit.pink {
    background-color: pink;
    height: auto;
}

div.postit.orange {
    background-color: orange;
    height: auto;
}

div.postit.pink h3 {
    margin-bottom: 10px;
}

#group-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

#group-container section {
    width: 45vw;
}

table {
    border-collapse: collapse;
    font-size: 0.6rem;
}

th,
td {
    border: 1px solid;
    padding: 1vh 1vw;
}

td.timeslot {
    background-color: white;
}

td.timeslot.onebusy {
    background-color: rgba(255, 0, 0, 0.2);
}

td.timeslot.twobusy {
    background-color: rgba(255, 0, 0, 0.4);
}

td.timeslot.threebusy {
    background-color: rgba(255, 0, 0, 0.6);
}

td.timeslot.fourbusy {
    background-color: rgba(255, 0, 0, 0.8);
}

ul {
    margin: 0;
    padding: 0;
}

ul li {
    margin: 4px 0;
}
