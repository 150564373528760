#navbar-container {
    margin-bottom: 8vh;
}

#navbar {
    color: white;
    background-color: black;
    width: 100%;
    height: 8vh;
    position: fixed;
    top: 0px;
    left: 0px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

#navbar div {
    width: 50vw;
    display: flex;
    justify-content: flex-end;
}

#navbar a {
   text-decoration: none;
   color: white;
}

#powered-by-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
}

#powered-by-container h2 {
    font-size: 12px;
}

#powered-by-container img {
    margin-left: 8px;
    width: auto;
    height: 2.5vh;
}
