.new_group_page {
  color: black;
  height: 92vh;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input_box {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 80%;
}

.input_fields {
  align-items: center;
  font-size: 20px;
  height: 10vh;
  width: 100%;
}
.text_box {
  margin-top: 10px;
  font-size: 20px;
  width: 100%;
  text-align: center;
  border: none;
  background: transparent;
  border-bottom: purple 1px solid;
}

.btn_box {
  display: flex;
  flex-direction: row;
  color: white;
  height: 50px;
  gap: 20px;
}
.btn {
  border-radius: 10px;
  width: 20vw;
  height: 50px;
  background: transparent;
  border: black solid 2px;
}
.btn_accept {
  background: limegreen;
  color: white;
}
.btn_delete {
  background-color: red;
}
.invite_link_box {
  display: flex;
  background-color: transparent;
  margin-top: 30px;
  width: 80%;
  height: 100%;
  border-radius: 10px;
  color: black;
  align-items: center;
  justify-content: center;
  text-decoration: underline purple;
  border: black 1px solid  ;
}
.check_boxes{
  display:flex;
  flex-direction: rows;
  justify-content: center;
  align-items: center;
  width:100%;
}
.input_fields_check_box{
  display:flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  height: 10vh;
  width: 100%;
}

section#postits-container {
    width: 100vw;
    height: 92vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
}

section#postits-container div.postit {
    height: auto;
}

div.linkbox p {
    margin: 12px 0;
}
