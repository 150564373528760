#page-container {
    width: 100vw;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center
}

#page-container section {
    box-sizing: border-box;
    width: 100vw;
    height: 40vh;
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

div.ppostit {
    background-color: yellow;
    border: 2px solid;
    width: 90%;
    margin: 10vh 5vw;
    margin-bottom: 2vh;
    height: 30vh;
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

div.ppostit.ppink {
    background-color: pink;
}

div.ppostit.oorange {
    background-color: orange;
}

a.signin-button {
    margin-top: 12px;
    width: 40%;
}

a.signin-button img {
    height: 100%;
}
